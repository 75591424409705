<template>
  <v-app>   
    <v-main>
      <v-container 
            class="fill-height justify-center" 
            fluid
        >
           <v-row 
            class="text-center"
            style="z-index:10"
          >
              <v-col cols="12">
                <v-img
                  :src="require('../assets/logo.png')"
                  class="my-3"
                  contain
                  height="250"
                />
              </v-col>

              <v-col cols="12" class="mb-4">
                <h1 class="primary--text display-2 font-weight-bold mb-3">
                  Welcome to {{ schoolName }}
                </h1>

                <p class="display-1 font-weight-light primary--text">
                  Sixth Form Application Online Portal
                </p>  
              </v-col>

              <v-col
                cols="12"
                md="4"
                offset-md="2"
                
              >
                  <v-btn
                    color="primary"
                    dark
                    large                    
                    @click="createApplication"
                  >
                    New Application
                  </v-btn>
              </v-col>  
              <v-col
                cols="12"
                md="4"
              >
                  <v-btn
                    color="primary"                    
                    outlined
                    large
                    @click="existingApplication"
                  >
                    Existing Application
                  </v-btn>
              </v-col> 
           </v-row>
        </v-container>

        <v-overlay
            :value="overlay"
            opacity="0.8"
            z-index="10"                    
        >
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>                   
        </v-overlay>

        <v-dialog
          v-model="dialog"
          width="350"
        >
          <v-expand-transition>
            <v-card
              v-show="existingApplicationLogin" 
              class="pb-4"
            >
              <v-card-title class="primary white--text">
                Existing Application Login
              </v-card-title>
              <v-card-text class="pa-6">
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="applicationId"
                      label="Application ID"
                      clearable
                      autofocus
                      v-on:keyup="keyPress"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="password"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show ? 'text' : 'password'"
                      label="Birth Certificate Pin"
                      clearable
                      @click:append="show = !show"
                      ref="password"
                      hide-details
                      v-on:keyup="keyPressPassword"
                    ></v-text-field>
                  </v-col>
                </v-row>
                
              </v-card-text>
              <v-card-actions >
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="submit"
                  block
                >
                  Login
                </v-btn>
              </v-card-actions>
              <v-alert
                v-model="alert"
                color="red"
                border="left"
                colored-border
                dismissible
                type="error"
                class="red--text mx-4 mt-4"
                outlined
              >
                {{ errorMessage }}
              </v-alert>

              <v-overlay
                :value="overlayLogin"
                absolute
              >
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>  
              </v-overlay>
            </v-card>
          </v-expand-transition>
        </v-dialog>

        <v-dialog
          v-model="dialogMessage"
          width="370"
        >
          <v-card flat>
            <v-alert
              border="bottom"
              colored-border
              type="warning"
              prominent
              class="text-h6 font-weight-light mb-0"
            >
              <v-row >
                <v-col class="pa-0" cols="1" offset="10">
                  <v-btn
                    icon
                    style="position:absolute; top:3px; right:3px;"
                    @click="dialogMessage = false"
                  >
                    <v-icon color="red">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col>
                  Sorry but the Application period has ended.
                </v-col>
              </v-row>
              
            </v-alert>
          </v-card>
        </v-dialog>
      
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  data: function () {
    return {
      application: {
        application_id: null,
      },
      overlay: false,
      dialog: false,
      applicationId: '',
      password: '',
      show: false,
      alert: false,
      errorMessage: 'Error',
      overlayLogin: false,
      existingApplicationLogin: false,
      dialogMessage: false,
    }
  },

  computed: {
    ...mapGetters({
      schoolName: 'application/getSchoolName',
    })
  },

  methods: {
    ...mapActions({
      postApplication: 'application/postApplication',
      postApplicationGrades: 'application/postApplicationGrades',
      postApplicationSubjectChoices: 'application/postApplicationSubjectChoices',
      getCSRFCookie: 'application/getCSRFCookie',
      getApplicationExisting: 'application/getApplicationExisting',
      adminSignIn: 'application/adminSignIn',
      getApplicationsLockStatus: 'application/getApplicationsLockStatus',
    }),

    ...mapMutations({
      setApplication: 'application/setApplication',
      setApplicationGrades: 'application/setApplicationGrades',
      setApplicationSubjectChoices: 'application/setApplicationSubjectChoices',
      setApplicationExisting: 'application/setApplicationExisting',
      setAuthenticated: 'application/setAuthenticated',
    }),

    async createApplication () {
      this.overlay = true;
      try {
        const { data: { locked } } = await this.getApplicationsLockStatus();
        console.log(locked);
        if(locked){
          this.overlay = false;
          this.dialogMessage = true;
          console.log('applications closed');
          return;
        }
        const response = await this.getCSRFCookie();
        console.log(response);
        const { data } = await this.postApplication();
        this.application.application_id = data.application_id;
        this.application.year = data.year;
        sessionStorage.setItem('application', JSON.stringify(this.application));
        this.overlay = false;
        this.$router.replace('/new-application');
      } catch (error) {
        if(error.response) console.log(error.response);
        else console.log(error);
        this.overlay = false;
      }
    },

    existingApplication () {
      console.log('existing application');
      this.dialog = true;
      setTimeout(() => {
        this.existingApplicationLogin = true;
      }, 700)
      
    },

    keyPress (e) {            
        if(e.keyCode === 13){
            //console.log(e);
            this.$refs.password.focus();
        }
    },

    keyPressPassword (e) {            
        if(e.keyCode === 13){
            this.submit();
        }
    },
    
    submit () {
      if(this.applicationId == 'Admin'){
        this.adminLogin();
        return
      }
      if(this.password && this.applicationId){
        this.getApplication();
      }     
      else{
        this.alert = true;
        this.errorMessage = "Please complete all fields."
      }
    },

    async getApplication () {
      this.alert = false;
      this.overlayLogin = true;
      try {
        this.setApplicationExisting({
          applicationId: this.applicationId,
          birthCertificatePin: this.password
        })
        const { data } = await this.getApplicationExisting();

        if(data){
          this.loadExistingApplication(data);
          
        }
        else{
          this.errorMessage = "Application with the credentials supplied not found."
          this.alert = true;
        }
      } catch (error) {
        if(error.response) console.log(error.response);
        else console.log(error);
        this.alert = true;
        this.errorMessage = "An Error has occured."
      }
      this.overlayLogin = false;
    },

    loadExistingApplication (data) {
      this.application.application_id = data.application_id;
      this.application.year = data.year;
      sessionStorage.setItem('application', JSON.stringify(this.application));
      this.existingApplicationLogin = false;
      setTimeout(() => {
        this.$router.replace('/new-application');
      }, 600);      
    },

    async adminLogin () {
      this.overlayLogin = true;
      console.log('login admin');
      try {
        this.setApplicationExisting({
          name: this.applicationId,
          password: this.password
        })
        await this.getCSRFCookie();
        const { data } = await this.adminSignIn();
        this.setAuthenticated(true);
        this.overlayLogin = false; 
        sessionStorage.authenticated = data;
        this.existingApplicationLogin = false;
        setTimeout(() =>{
          this.$router.replace('/admin');
        }, 700)
        
      } catch (error) {
        this.overlayLogin = false;
        this.errorMessage = "An Error has occured."
        if(error.response){
          console.log(error.response);
          let { data: { message } } = error.response
          if(message){
            this.errorMessage = message;
          }
        }
        else console.log(error);
        this.alert = true;
      }           
    }
    
  },
  
}
</script>
